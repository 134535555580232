import { gsap } from 'gsap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import BackgroundVideo from '@/components/backgroundVideo.vue'; // import IntegrationSteps from '@/components/integrationSteps.vue';

import QuoteModule from '@/components/quoteModule.vue'; // import ContactBlock from '@/components/contactBlock.vue';

import industryHighlightText from '@/components/industryHighlightText.vue';
import mynFeatures from '@/components/mynFeatures.vue';
import '@/components/industry.css';
gsap.registerPlugin(ScrollTrigger);
export default {
  components: {
    BackgroundVideo: BackgroundVideo,
    // IntegrationSteps,
    QuoteModule: QuoteModule,
    // ContactBlock,
    industryHighlightText: industryHighlightText,
    mynFeatures: mynFeatures
  },
  data: function data() {
    return {
      src: 'https://res.cloudinary.com/myn/image/upload/v1599335602/sample.jpg'
    };
  },
  mounted: function mounted() {
    AOS.init(); // eslint-disable-next-line no-unused-expressions

    gsap.from('.aboutHeader', {
      scrollTrigger: '.aboutHeader',
      x: 400,
      rotation: 360,
      duration: 3
    });
    ScrollTrigger.defaults({
      toggleActions: 'restart pause resume pause'
    });
    gsap.to('.home', {
      scrollTrigger: '.home'
    });
  }
};